.footer{
    background-color: black;
    padding: 2.5rem 0;
    font-family: "Public Sans",sans-serif;
    color:white;
}
.footer-flex-1{
    display: flex;
    justify-content: space-around;
    padding-bottom: 2rem;
}
.footer-flex-11 ul li{
    display: inline;
    margin:0 1rem;
    
}
.footer-flex-12 ul li{
    display: inline;
    margin:0 1rem;
    color: white;
}
.footer a{
    text-decoration: none;
    color: white;
}
.footer-flex-2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 2rem;
}
.contact-info{
    display: flex;
    width: 40%;
    justify-content: space-around;
}
.footer-phone{
    display: flex;
    align-items: center;
    user-select: text;
}
.footer-email{
    display: flex;
    align-items: center;
    user-select: text;
}
.phone-icon,.email-icon{
    margin-right: 1rem;
}
.copyrights{
    margin-right: 0.8rem;
}
hr{
    width: 80%;
}
.phone-header,.email-header{
    color:#d7d7d7;
    font-size: 0.9rem;
}
.footer-reversed{
    flex-direction: row-reverse;
}
@media (max-width:768px){
    .footer-flex-1{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .footer-flex-2{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-info{
        width: 100%;
    }
    .footer-flex-11 ul li{
        font-size: 0.8rem;
        margin: 0 0.5rem;
    }
    .contact-info{
        flex-direction: column;
        align-items: center;
    }
    .footer ul{
        padding-left: 0;
    }
    .copyrights{
        text-align: center;
        margin-right: 0;
        margin-left: 1rem;
    }
}