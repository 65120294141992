.services{
    background-color: #1B65A5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;    
    padding-bottom: 4.7rem;
}
.services-title{
    font-size: 3.7rem;
    font-family: cooper-medium;
    color: white;
}
.services-cards-container{
    display: grid;
    justify-content: space-around;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
    width: 100%;

}
.services-card{
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding-top:1rem;
    padding-left: 1rem;
    color:#141414;
    font-family: "Public Sans", sans-serif;
    
}
.card-inverted{
    align-items: flex-end;
    color:#141414;
    font-family: "Public Sans", sans-serif;
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding-top:1rem;
    padding-right: 1rem;
}
.engineering-icon{
    font-size: 3.5rem !important;
    padding-right: 1rem;
}

.card-desc{
    color: #969696;
    margin-top: -1rem;
    
}
.services-cta{
    background-color: white;
    border-radius: 15px;
    font-family: "Public Sans" , sans-serif;
    height: fit-content;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #141414;
    border: solid 2px transparent;
    
}
.services-cta-reversed{
    background-color: white;
    border-radius: 15px;
    font-family: "Public Sans" , sans-serif;
    height: fit-content;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #141414;
    border: solid 2px transparent;
}
.services-cta-reversed:hover{
    background-color: transparent;
    border:solid 2px white;
    transition: all ease-in-out 200ms;
}

.services-cta:hover{
    background-color: transparent;
    border:solid 2px white;
    transition: all ease-in-out 200ms;

}
.services-cta-link {
    text-decoration: none;
    height: fit-content;
    
}
.services-cta h4{
    font-weight: 1000;
}

@media (max-width:768px){
    .services{
        height: auto;
        padding-top: 4rem;
    }
    .services-cards-container{
        grid-template-columns: 80%;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

    }
    .services-title{
        font-size: 2rem;
        margin-bottom: 4rem;
    }
}