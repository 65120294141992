.intro{
    padding: 8rem 2rem;
}
.intro span{
    font-family: cooper-book;
    font-size: 2.3rem;
    color: #646464;
    
}
.align-right{
    text-align: right;
}


@media(max-width:768px){
    .intro span{
        font-size: 1.8rem;
    }
    .align-right{
        text-align: right;
    }
}