.hero{
    position: relative;
    height: 100vh;
    width: 100%;
}
.hero-inner-container{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: fit-content;
    width: fit-content;
}
.hero-image{
    width: 95vw;
    height: 90vh;
    object-fit: cover;
    object-position: bottom;
    border-radius: 35px;
    transform: scale(-1,1);


}
.hero-image-inverted{
    width: 95vw;
    height: 90lvh;
    object-fit: cover;
    object-position: bottom;
    border-radius: 35px;
}
.hero-title{
    position: absolute;
    bottom: 0%;
    left: 0%;
    
}
.hero-title-inverted{
    position: absolute;
    bottom: 0%;
    right: 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.title-1{

    background-color: white;
    height: 4rem;
    width:  28rem;
    display: flex;
    align-items: center;
    border-top-right-radius: 25px;
    margin-bottom: -0.1rem;
    position: relative;
}
.title-1 h1{
    z-index: 100;
}
.title-1-inverted{
    background-color: white;
    height: 4rem;
    width:  17rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top-left-radius: 25px;
    margin-bottom: -0.1rem;
    position: relative;
}
.title-1::after{
    content:" ";
    width: 25px;
    height: 50px;
    position: absolute;
    top:-50px;
    background-color: transparent;
    z-index: 50;
    border-bottom-left-radius: 25px;
    box-shadow: 0 25px 0 0 #fff;
}
.title-1::before{
    content:" ";
    width: 25px;
    height: 50px;
    position: absolute;
    top:12px;
    right: -25px;
    border-bottom-left-radius: 25px;
    background-color: transparent;
    z-index: 50;
    
    box-shadow: 0 25px 0 0 white;
}
.title-1-inverted::after{
    content:" ";
    width: 25px;
    height: 50px;
    position: absolute;
    top:-50px;
    background-color: transparent;
    z-index: 50;
    border-bottom-right-radius: 25px;
    box-shadow: 0 25px 0 0 #fff;
}
.title-1-inverted::before{
    content:" ";
    width: 25px;
    height: 50px;
    position: absolute;
    top:12px;
    left: -25px;
    border-bottom-right-radius: 25px;
    background-color: transparent;
    z-index: 50;
    
    box-shadow: 0 25px 0 0 white;
}

.title-2{
    width: 43rem;
    background-color: white;
    height: 4rem;
    display: flex;
    align-items: center;
    border-top-right-radius: 25px;
    position: relative;
    
}
.title-2::after{
    content:" ";
    width: 25px;
    height: 50px;
    position: absolute;
    bottom:4px;
    right: -25px;
    border-bottom-left-radius: 25px;
    background-color: transparent;
    z-index: 50;
    
    box-shadow: 0 25px 0 0 white;
}
.title-2-inverted::after{
    content:" ";
    width: 25px;
    height: 50px;
    position: absolute;
    bottom:4px;
    left: -25px;
    border-bottom-right-radius: 25px;
    background-color: transparent;
    z-index: 50;
    box-shadow: 0 25px 0 0 white;
}
.title-2-inverted{
    width: 27rem;
    background-color: white;
    height: 4rem;
    display: flex;
    align-items: center;
    border-top-left-radius: 25px;
    justify-content: flex-end;
    position: relative;
}

.title-2 h2{
    font-size: 2.4rem;
    color: #969696;
    font-family: cooper-semi;
}
.title-2-inverted h2{
    font-size: 2.4rem;
    color: #969696;
    font-family: cooper-semi;
}

.hero-title h1{
    font-size: 3rem;
    font-family: cooper-semi;
    color: #1B65A5;
}
.title-1-inverted h1{
    font-size: 3rem;
    font-family: cooper-semi;
    color: #1B65A5;
    z-index: 100;
}
.hero-sub{
    position: absolute;
    right: 7%;
    top:55%;
    transform: translate(0,-50%);
    color: rgba(215,215,215,0.7);
    font-family: cooper-light;
    font-size: 1.8rem;
    line-height: 1.3rem;
    
}
.hero-sub-inverted{
    position: absolute;
    left: 7%;
    top:55%;
    transform: translate(0,-50%);
    color: rgba(215,215,215,0.7);
    font-family: cooper-light;
    font-size: 1.8rem;
    line-height: 1.3rem;
    text-align: right;
}
.hero-cta-link{
    text-decoration: none;
}
.selected{
    color:rgba(255,255,255,1);
}

.unselected span{
    visibility: hidden;
}
.selected span{
    visibility: visible;
    font-family: cooper-bold;
}
.hero-cta{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1B65A5;
    color: white;
    border: none;
    border-radius: 5px;
    width: 70%;
    padding-left: 0.6rem;
    height: 2.2rem;

}
.hero-cta-inverted{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1B65A5;
    color: white;
    border: none;
    border-radius: 5px;
    width: 70%;
    padding-right: 0.6rem;
    height: 2.2rem;
    margin-left: 3rem;
}
.hero-cta:hover{
    background-color: #154a79;
    transition: all ease-in-out 200ms;
    cursor: pointer;
}
.hero-cta-inverted:hover{
    background-color: #154a79;
    transition: all ease-in-out 200ms;
    cursor: pointer;
}
.hero-cta p{
    font-family: cooper-bold;
    font-size: 1.1rem;
   
}
.hero-cta-inverted p{
    font-family: cooper-bold;
    font-size: 1.1rem;
}
.hero-cta-down{
    font-size: 2rem !important;
}
.down-arrow-container{
    display: inline;
    background-color: #1B65A5;
    color: white;
    margin-left: 0.5rem;
    border-radius: 50%;
    padding: 0 8px;
    

}
.down-arrow{
    font-size: 18px !important;
}
.down-arrow-container:hover{
    cursor: pointer;
    background-color: rgba(215,215,215,0.7);

    transition: all ease-in-out 200ms;
}
@media (max-width:768px){

    .title-1{

        background-color: white;
        height: 4rem;
        width:  15rem;
        display: flex;
        align-items: center;
        border-top-right-radius: 25px;
        margin-bottom: -0.1rem;
        position: relative;
    }
    .title-2{
        width: 20rem;
        background-color: white;
        height: 4rem;
        display: flex;
        align-items: center;
        border-top-right-radius: 25px;
        
    }
    .title-1-inverted{
        width: 10rem;
    }
    .title-2-inverted{
        width: 19rem;
    }
    .title-2 h2{
        font-size: 1.1rem;
        color: #969696;
        font-family: cooper-semi;
    }
    .title-2-inverted h2{
        font-size: 1.4rem;
    }
    .hero-title h1{
        font-size: 1.4rem;
        font-family: cooper-semi;
        color: #1B65A5;
    }
    .hero-title-inverted h1{
        font-size: 1.7rem;
    }
    .hero-image{
        width: 95vw;
        height: 90vh;
        object-fit: cover;
        object-position: bottom right;
        border-radius: 35px;
        transform: scale(-1,1);
    
    
    }
    
    .hero-cta{
        width: 35%;
    }
    .hero-image-inverted{
        width: 95vw;
        height: 90vh;
        object-fit: cover;
        object-position: bottom right;
        
    }
    .hero-sub{
        position: absolute;
        left: 5%;
        top:50%;
        transform: translate(0,-50%);
        color: rgba(215,215,215,0.7);
        font-family: cooper-medium;
        font-size: 1.3rem;
        line-height: 0.8rem;

        
    }
    .hero-sub-inverted{
        text-align: right;
        position: absolute;
        right:5%;
        top:50%;
        transform: translate(0,-50%);
        color: rgba(215,215,215,0.7);
        font-family: cooper-light;
        font-size: 1.3rem;
        line-height: 0.8rem;
        
    }
    .hero-cta-inverted{
        margin-left: 0;
        width: 35%;
        float: right;

    }
    .down-arrow-container{
        display: inline;
        background-color: #1B65A5;
        color: white;
        margin-left: 0.5rem;
        border-radius: 50%;
        padding: 0.3rem 0.7rem;
        position: relative;
        
    
    }
    .down-arrow{
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}
