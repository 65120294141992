.header{
    display: flex;
    justify-content: center;
    position: absolute;
    top:8vh;
    left: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 4;
}
nav{
    border-radius: 20px;
    width: 90vw;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.nav-list li{
    display: inline;
    margin: 0 1rem;
    font-family: "Public Sans", sans-serif;
    font-weight: 800;
    color:#3a3a3a;
    font-size: 1rem;
}
.nav-list li:hover{
    color:#1B65A5;
    transition: all ease-in-out 200ms;
    cursor: pointer;
}

.left-menu-items{
    padding: 0;
}
.right-menu-items{
    padding: 0;
}
.logo{
    width: 2.8rem;
}
.logo-container{
    width: fit-content;
}
.nav-links{
    text-decoration: none;
    color: #3a3a3a;
}
.nav-links:hover{
    color:#1B65A5;
    transition: all ease-in-out 200ms;
}

.menu-icon-container{
    display: none;
}
.menu{
    display: none;
}
@media (max-width:768px){
    .header{
        display: flex;
        justify-content: center;
        position: absolute;
        top:8vh;
        left: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: 4;
    }
    nav ul{
        display: none;
        
    }
    nav {
        width: 80vw;
    }
    .menu-icon-container{
        display: block;

    }
    .menu{
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        background-color: white;
        z-index: 100;
        width: 100%;
        display: flex;
        align-items: center;

    }
    .menu-list{
        display: flex;
        list-style: none;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 60%;
        width: 100%;
        padding-left: 0;
        
    }
    .menu-links{
        text-decoration: none;
        color:#1B65A5;
        font-family: cooper-semi;
        font-size: 3rem;
    }
    .menu-language{
        color:#1B65A5;
        font-family: cooper-semi;
        font-size: 1rem;
    }
    .cancel-icon-container{
        position: absolute;
        top:10%;
        right:10%;
    }
    .cancel-icon{
        color: #1B65A5;
    }
    .menu-item a{
        font-size:  1.4rem;
        text-align: center;
    }
}