.contact-us{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 70vh;
    padding-bottom: 20rem;
}
.contact-us h1{
    font-family: cooper-medium;
    font-size: 3.7rem;
}
.form-container{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr 1fr 1fr 3fr 1fr;
    width: 60%;
    justify-content: space-around;
    row-gap: 1rem;
    grid-template-areas: "name name" "email email" "phone phone" "message message" "button button";


}
.arabic{
    text-align: right;
}
.fullname{
    grid-area: name;

}
.email-address{
    grid-area: email;



}
.phone{
    grid-area: phone;



}
.subject{
    grid-area: subject;


}
.message{
    grid-area: message;
    font-family:'Public Sans',sans-serif ;
    resize: none;
}
.fullname,.email-address,.phone,.subject,.message{
    padding: 15px;
    border-radius: 12px;
    border:solid 1px #979797;

}
.send-button{
    grid-area:button;
    background-color: #1B65A5;
    font-size: 1.1rem;
    color: white;
    font-family: "Public Sans" , sans-serif;
    border-radius: 7px;
    padding: 1rem 0;
    width: 50%;
    justify-self: center;
    border: none;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.send-button:hover{
    background-color: #103d64;
    transition: all ease-in-out 200ms;
    cursor: pointer;
}
@media (max-width:768px){
    .contact-us h1{
        font-size: 2rem;
        
    }
    .contact-us{
        padding-bottom: 15rem;
        height: 40vh;
        padding-top: 6rem;
    }
    .fullname,.email-address,.phone,.subject,.message{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .send-button{
        grid-area:button;
        background-color: #1B65A5;
        font-size: 1rem;
        color: white;
        font-family: "Public Sans" , sans-serif;
        border-radius: 7px;
        padding: 1rem 0;
        width: 70%;
        justify-self: center;
        border: none;
    }
}
